<template>
  <Content title="About">
    <template v-slot:content>
      <template v-for="(group, i) in groupedArticles">
        <div :key="group.title">
          <h3 class="pl-4 cubtitle-1" :class="{ 'pt-3': i === 0 }">{{ group.title }}</h3>
          <v-container grid-list-lg fluid wrap>
            <v-layout wrap>
              <v-flex xs12 md6 v-for="article in group.articles" :key="article.id">
                <v-card hover ripple :to="{ name: 'AboutDetails', params: { id: article.id } }">
                  <v-card-title>
                    <v-avatar>
                      <v-img class="card-image" :src="`${article.coverImage}`"></v-img>
                    </v-avatar>
                    <v-layout column>
                      <v-flex class="pb-0">
                        <h3 class="headline">{{article.title}}</h3>
                      </v-flex>
                      <v-flex class="pt-0">
                        <p class="caption">{{article.teaser}}</p>
                      </v-flex>
                    </v-layout>
                  </v-card-title>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
      </template>
    </template>
  </Content>
</template>

<script>
import Content from '@/views/Content.vue'
import { mapState } from 'vuex';

export default {
  name: "About",
  components: {
    Content
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('about', ['groupedArticles'])
  },
  methods: {},
};
</script>